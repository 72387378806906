import React from 'react'
import '../common/css/textPageContainer.css'

export default function HomePage() {
    return <div className="text-page-container">
        <p>Coming Soon</p>
        {/*<h1>Welcome to Jif Bot, a shitpost bot turned personal assistant. Designed specifically to meet my own needs, Jif Bot has various functionality to aid in discussion, provide basic tools, and bolsters some additional goodies that nobody wants or needs! Feel free to add my son to your server and give it a try: </h1>
        <button>Invite me!</button>
        <h2>Some basic features:</h2>*/}
    </div>
}